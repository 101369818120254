<template>
  <div class="home wrapper">
    <div class="wk_home_intro">
      <div class="wk_intro__row_left">
        <!-- todo remove this line, only for debug -->
        <h1 class="wk_intro__title" v-if="loggedIn">Welcome {{ name }},</h1>
        <h1 class="wk_intro__title">what would you like to do?</h1>
      </div>
      <div class="wk_intro__row_right">
        <ul class="wk_intro__nav">
          <li class="wk_intro__nav_item" v-if="!loggedIn">
            <router-link to="/login">
              <span class="wk_item_label">login</span>
              <span class="wk_item_arrow"></span>
            </router-link>
          </li>
          <li class="wk_intro__nav_item" v-if="loggedIn">
            <router-link to="/collections">
              <span class="wk_item_label">manage collections</span>
              <span class="wk_item_arrow"></span>
            </router-link>
          </li>
          <!--          <li class="wk_intro__nav_item" v-if="loggedIn">-->
          <!--            <router-link to="/finishes">-->
          <!--              <span class="wk_item_label">manage finishes</span>-->
          <!--              <span class="wk_item_arrow"></span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <!--          <li class="wk_intro__nav_item" v-if="loggedIn">-->
          <!--            <router-link to="/colors">-->
          <!--              <span class="wk_item_label">manage colors</span>-->
          <!--              <span class="wk_item_arrow"></span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="wk_intro__nav_item" v-if="loggedIn">
            <router-link to="/producibility">
              <span class="wk_item_label">producibility</span>
              <span class="wk_item_arrow"></span>
            </router-link>
          </li>
          <li class="wk_intro__nav_item" v-if="loggedIn">
            <router-link to="/stocks">
              <span class="wk_item_label">stocks</span>
              <span class="wk_item_arrow"></span>
            </router-link>
          </li>
          <!--          <li>LoggedIn: {{ loggedIn }}</li>-->
          <!--          <li>Name: {{ name }}</li>-->
          <!--          <li>E-mail: {{ username }}</li>-->
          <!--          <li style="font-size: 10px">Token: {{ token }}</li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
}

.home.wrapper {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
}

.wk_home_intro {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

h1.wk_intro__title {
  font-size: 40px;
  font-weight: 800;
}

.wk_intro__row_right,
.wk_intro__row_left {
  width: 50%;
}

ul.wk_intro__nav {
  list-style-type: none;
  margin: 0;
  font-size: 20px;
  text-decoration: none;
  padding: 0;
}

li.wk_intro__nav_item {
  margin-bottom: 20px;
}

li.wk_intro__nav_item > a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.wk_item_arrow {
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(../../public/img/abet_arrow.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
}

.wk_intro__nav_item > a:hover .wk_item_arrow {
  transform: translateX(-20%);
  transition: transform 0.3s ease-in-out;
}

.v-sheet.v-theme--light.rounded-sm {
  width: 100%;

  form {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    .v-input.v-input--horizontal.v-input--density-default.v-locale--is-ltr.v-text-field.v-input--plain-underlined {
      width: 80%;
      display: block;
      flex-shrink: 0;
    }

    .v-sheet.v-theme--light.rounded-sm,
    form,
    .v-btn.v-btn--block.v-btn--elevated.v-theme--light.bg-primary.v-btn--density-default.rounded-0.v-btn--size-default.v-btn--variant-elevated.mt-2 {
      width: 20%;
      flex-grow: 0;
      display: block;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "home",
  computed: {
    ...mapGetters("auth", ["loggedIn"]),
    ...mapGetters("auth", ["name"]),
    ...mapGetters("auth", ["username"]),
    ...mapGetters("auth", ["token"]),
  },
  data: () => ({}),
  methods: {
    // userHasRole,
  },
  mounted() {
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }
  },
};
</script>
