<script>
import axios from "axios";
import { countryName } from "@/utils/helper";
import {
  API_URL,
  defaultTimeout,
  notifyDeletion,
  notifyError,
  notifySaving,
} from "@/utils/const";
import authHeader from "@/services/data.service";

export default {
  name: "standard-component",
  emits: ["update", "delete"],
  data() {
    return {
      selectedSpessore: null,
      selectedFormato: null,
      selectedFinitura: null,
      text: "",
      snackbar: false,
      dialog: false,
      adding: false,
    };
  },
  props: {
    _id: { required: true, type: String },
    nazione: { required: false, type: String },
    lista_spessori: { required: true, type: Array },
    lista_finiture: { required: true, type: Array },
    lista_formati: { required: true, type: Array },
    spessore: { required: true, type: Number },
    finitura: { required: true, type: Array },
    formato: { required: true, type: String },
  },
  mounted() {
    this.selectedSpessore = this.spessore;
    this.selectedFinitura = this.finitura;
    this.selectedFormato = this.formato;
  },
  methods: {
    defaultTimeout() {
      return defaultTimeout;
    },
    countryName,
    enableAdding() {
      this.adding = true;
    },
    disableAdding() {
      this.adding = false;
      this.selectedFormato = this.formato;
      this.selectedSpessore = this.spessore;
      this.selectedFinitura = this.finitura;
    },
    checkValues() {
      return (
        this.selectedFormato === this.formato &&
        this.selectedSpessore === this.spessore &&
        this.selectedFinitura.length === this.finitura.length &&
        this.selectedFinitura.every((item, i) => item === this.finitura[i])
      );
    },
    async updateStandard() {
      const body = {
        _id: this._id,
        formato_std: this.selectedFormato,
        spessore_std: this.selectedSpessore,
        finitura_std: this.selectedFinitura,
      };
      await axios
        .put(API_URL + "decorativi/standard", body, {
          headers: authHeader(),
        })
        .then(() => {
          this.$emit("update", body);
          this.text = notifySaving;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
          this.selectedFormato = this.formato;
          this.selectedSpessore = this.spessore;
          this.selectedFinitura = this.finitura;
        });
      this.adding = false;
    },
    async updateStandardCons() {
      const body = {
        _id: this._id,
        formato_std: this.selectedFormato,
        spessore_std: this.selectedSpessore,
        finitura_std: this.selectedFinitura,
      };
      await axios
        .put(API_URL + "cons/decorativo/standard", body, {
          headers: authHeader(),
        })
        .then(() => {
          this.$emit("update", body);
          this.text = notifySaving;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
          this.selectedFormato = this.formato;
          this.selectedSpessore = this.spessore;
          this.selectedFinitura = this.finitura;
        });
      this.adding = false;
    },
    async deleteStandardCons(id) {
      const body = { _id: id };
      await axios
        .delete(API_URL + "cons/decorativo/delete", {
          headers: authHeader(),
          data: body,
        })
        .then(() => {
          this.$emit("delete", id);
          this.text = notifyDeletion;
          this.snackbar = true;
        })
        .catch(() => {
          this.text = notifyError;
          this.snackbar = true;
        });
    },
  },
};
</script>

<template>
  <div class="d-flex flex-row w-100">
    <div
      v-if="!nazione"
      class="wk_section_label w-25 ma-2 text-right align-self-center"
    >
      <b>STANDARD GLOBALE</b>
    </div>
    <div v-else class="w-25 ma-2 text-center align-self-center">
      <v-chip class="w-100 wk_consociata_chip" @click="dialog = true">
        {{ countryName(nazione) }}
        <v-icon end icon="mdi-close"></v-icon>
        <v-dialog v-model="dialog" width="auto">
          <v-card>
            <v-card-text> Confermi di voler cancellare?</v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="
                  dialog = false;
                  deleteStandardCons(_id);
                "
                block
                >Conferma
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-chip>
    </div>
    <div class="wk_field_box w-25 mx-2">
      <span class="wk_field_label" v-if="!nazione">Spessore (mm)</span>
      <v-combobox
        class="wk_field_selectbox"
        v-model="selectedSpessore"
        :items="lista_spessori"
        auto-select-first
        @update:model-value="checkValues() ? disableAdding() : enableAdding()"
      ></v-combobox>
    </div>
    <div class="wk_field_box w-25 mx-2">
      <span class="wk_field_label" v-if="!nazione">Formato (cm)</span>
      <v-combobox
        class="wk_field_selectbox"
        v-model="selectedFormato"
        :items="lista_formati"
        auto-select-first
        @update:model-value="checkValues() ? disableAdding() : enableAdding()"
      ></v-combobox>
    </div>
    <div class="wk_field_box w-25 mx-2">
      <span class="wk_field_label" v-if="!nazione">Finitura</span>
      <!--      <v-combobox-->
      <!--        class="wk_field_selectbox"-->
      <!--        v-model="selectedFinitura"-->
      <!--        :items="lista_finiture"-->
      <!--        auto-select-firstù-->
      <!--        multiple-->
      <!--        @update:model-value="checkValues() ? disableAdding() : enableAdding()"-->
      <!--      ></v-combobox>-->
      <v-autocomplete
        class="wk_field_selectbox"
        v-model="selectedFinitura"
        :items="lista_finiture"
        item-title="Descrizione"
        item-value="Codice"
        multiple
        @update:model-value="checkValues() ? disableAdding() : enableAdding()"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :subtitle="item.raw.Codice"></v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <v-fade-transition>
      <div class="wk_update_standard" v-if="adding">
        <v-btn
          prepend-icon="mdi-check-circle-outline"
          variant="plain"
          size="regular"
          color="black"
          class="wk_update_standard_confirm"
          @click="!nazione ? updateStandard() : updateStandardCons()"
        ></v-btn>
        <v-btn
          prepend-icon="mdi-close-circle-outline"
          variant="plain"
          size="regular"
          color="black"
          class="wk_update_standard_delete"
          @click="disableAdding"
        ></v-btn>
      </div>
    </v-fade-transition>
  </div>

  <v-snackbar v-model="snackbar" :timeout="defaultTimeout()">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="snackbar = false"
        >Chiudi
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>
.wk_section_label {
  text-transform: uppercase;
  font-size: 14px;
}

.wk_field_box {
  position: relative;
}

.wk_field_selectbox {
  font-size: 14px;
}

span.wk_field_label {
  position: absolute;
  top: -15px;
  font-size: 14px;
  font-weight: 600;
}

.wk_consociata_chip
  >>> i.mdi-close.mdi.v-icon.notranslate.v-theme--light.v-icon--size-default.v-icon--end {
  position: absolute;
  right: 12px;
  font-size: 16px;
}

.wk_consociata_chip >>> .v-chip__underlay {
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid black !important;
  opacity: 1;
}

.wk_update_standard_confirm {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateX(100%) translateY(-50%);
  width: 30px;
  height: 30px;
}

.wk_update_standard_delete {
  position: absolute;
  top: 50%;
  right: -35px;
  transform: translateX(100%) translateY(-50%);
  width: 30px;
  height: 30px;
}

.wk_update_standard {
  display: flex;
  align-items: center;
  position: relative;
}
</style>
