<template>
  <v-app>
    <v-toolbar :elevation="2" shades color="black shades" id="wk_main_navbar">
      <div class="wk_logo_container">
        <router-link to="/">
          <svg
            id="abet_logo"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="201.044"
            height="23"
            viewBox="0 0 201.044 23"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rettangolo_1"
                  data-name="Rettangolo 1"
                  width="201.044"
                  height="23"
                />
              </clipPath>
            </defs>
            <g
              id="Raggruppa_1"
              data-name="Raggruppa 1"
              transform="translate(0 0)"
              clip-path="url(#clip-path)"
            >
              <path
                id="Tracciato_1"
                data-name="Tracciato 1"
                d="M396.025.361l-.906,2.988a.453.453,0,0,0,.453.589h4.935a.454.454,0,0,1,.453.453V22.546a.454.454,0,0,0,.453.453h3.667a.454.454,0,0,0,.453-.453V4.436a.454.454,0,0,1,.453-.453h4.935a.468.468,0,0,0,.453-.589L410.468.406a.45.45,0,0,0-.453-.317H396.387c-.136-.045-.317.091-.362.272"
                transform="translate(-216.216 -0.044)"
              />
              <path
                id="Tracciato_2"
                data-name="Tracciato 2"
                d="M174.317,18.608V.453A.454.454,0,0,0,173.864,0H170.2a.454.454,0,0,0-.453.453V22.5a.454.454,0,0,0,.453.453h9.689a.492.492,0,0,0,.453-.317l.906-2.988a.453.453,0,0,0-.453-.589h-5.976a.465.465,0,0,1-.5-.453"
                transform="translate(-92.891 0)"
              />
              <path
                id="Tracciato_3"
                data-name="Tracciato 3"
                d="M203.145,13.9l2.4-8.1a.236.236,0,0,1,.453,0l2.4,8.1a.453.453,0,0,1-.453.589h-4.392a.441.441,0,0,1-.407-.589m12.587,8.467L209.121.317A.45.45,0,0,0,208.669,0h-5.886a.492.492,0,0,0-.453.317l-6.61,22.049a.468.468,0,0,0,.453.589h3.848a.492.492,0,0,0,.453-.317l1.177-3.939a.45.45,0,0,1,.453-.317h7.289a.545.545,0,0,1,.453.317l1.177,3.939a.45.45,0,0,0,.453.317h3.848a.446.446,0,0,0,.408-.589"
                transform="translate(-107.095 0)"
              />
              <path
                id="Tracciato_4"
                data-name="Tracciato 4"
                d="M366.5,13.9l2.4-8.1a.236.236,0,0,1,.453,0l2.4,8.1a.453.453,0,0,1-.453.589h-4.392a.462.462,0,0,1-.407-.589m12.587,8.467L372.521.317A.45.45,0,0,0,372.068,0h-5.886a.492.492,0,0,0-.453.317l-6.61,22.049a.468.468,0,0,0,.453.589h3.848a.492.492,0,0,0,.453-.317l1.177-3.939a.45.45,0,0,1,.453-.317h7.289a.545.545,0,0,1,.453.317l1.177,3.939a.45.45,0,0,0,.453.317h3.849a.423.423,0,0,0,.362-.589"
                transform="translate(-196.515 0)"
              />
              <path
                id="Tracciato_5"
                data-name="Tracciato 5"
                d="M438.064,22.955H434.4a.454.454,0,0,1-.453-.453V.453A.454.454,0,0,1,434.4,0h3.667a.454.454,0,0,1,.453.453V22.5a.428.428,0,0,1-.453.453"
                transform="translate(-237.473 0)"
              />
              <path
                id="Tracciato_6"
                data-name="Tracciato 6"
                d="M304.064,22.955H300.4a.454.454,0,0,1-.453-.453V.453A.454.454,0,0,1,300.4,0h3.667a.454.454,0,0,1,.453.453V22.5c.045.272-.181.453-.453.453"
                transform="translate(-164.143 0)"
              />
              <path
                id="Tracciato_7"
                data-name="Tracciato 7"
                d="M258.719.317l-4.256,13.537a.455.455,0,0,1-.86,0L249.347.317A.45.45,0,0,0,248.894,0h-5.3a.454.454,0,0,0-.453.453V22.5a.454.454,0,0,0,.453.453h3.667a.454.454,0,0,0,.453-.453V10.1a.347.347,0,0,1,.679-.091l3.441,12.632a.45.45,0,0,0,.453.317h3.441a.45.45,0,0,0,.453-.317l3.441-12.632a.345.345,0,0,1,.679.091V22.5a.454.454,0,0,0,.453.453h3.667a.454.454,0,0,0,.453-.453V.453A.454.454,0,0,0,264.423,0h-5.3a.435.435,0,0,0-.407.317"
                transform="translate(-133.059 0)"
              />
              <path
                id="Tracciato_8"
                data-name="Tracciato 8"
                d="M331.123.453v13.13a.355.355,0,0,1-.679.136L325.1.272A.435.435,0,0,0,324.694,0h-5.3a.454.454,0,0,0-.453.453V22.5a.454.454,0,0,0,.453.453h3.667a.454.454,0,0,0,.453-.453V9.372a.355.355,0,0,1,.679-.136l5.3,13.447a.435.435,0,0,0,.407.272h5.3a.454.454,0,0,0,.453-.453V.453A.454.454,0,0,0,335.2,0h-3.667a.447.447,0,0,0-.407.453"
                transform="translate(-174.54 0)"
              />
              <path
                id="Tracciato_9"
                data-name="Tracciato 9"
                d="M119.125.361l-.906,2.988a.453.453,0,0,0,.453.589h4.935a.454.454,0,0,1,.453.453V22.546a.454.454,0,0,0,.453.453h3.667a.454.454,0,0,0,.453-.453V4.436a.454.454,0,0,1,.453-.453h4.935a.468.468,0,0,0,.453-.589L133.568.406a.45.45,0,0,0-.453-.317H119.487c-.136-.045-.317.091-.362.272"
                transform="translate(-64.684 -0.044)"
              />
              <path
                id="Tracciato_10"
                data-name="Tracciato 10"
                d="M93.362,18.663v-5.75a.454.454,0,0,1,.453-.453h5.75a.454.454,0,0,0,.453-.453V9.019a.454.454,0,0,0-.453-.453h-5.75a.454.454,0,0,1-.453-.453V4.446a.454.454,0,0,1,.453-.453h6.565a.545.545,0,0,0,.453-.317l.906-2.988A.468.468,0,0,0,101.285.1H89.2a.454.454,0,0,0-.453.453V22.6a.454.454,0,0,0,.453.453h14.488a.454.454,0,0,0,.453-.453V19.614a.454.454,0,0,0-.453-.453h-9.87a.494.494,0,0,1-.453-.5"
                transform="translate(-48.565 -0.055)"
              />
              <path
                id="Tracciato_11"
                data-name="Tracciato 11"
                d="M56.952,19.061H52.47a.428.428,0,0,1-.453-.453v-5.75a.428.428,0,0,1,.453-.453h4.618a3.329,3.329,0,1,1-.136,6.656M52.47,3.894h2.083a2.364,2.364,0,0,1,2.4,2.083,2.32,2.32,0,0,1-2.309,2.535H52.47a.454.454,0,0,1-.453-.453V4.392a.5.5,0,0,1,.453-.5m7.154,5.433a5.6,5.6,0,0,0,1.63-4.03A5.656,5.656,0,0,0,55.594,0H47.9a.454.454,0,0,0-.453.453V22.5a.454.454,0,0,0,.453.453h9.825a6.861,6.861,0,0,0,6.927-6.746,6.763,6.763,0,0,0-4.935-6.52c-.181,0-.226-.226-.091-.362"
                transform="translate(-25.963 0)"
              />
              <path
                id="Tracciato_12"
                data-name="Tracciato 12"
                d="M7.4,13.9,9.8,5.8a.236.236,0,0,1,.453,0l2.4,8.1a.453.453,0,0,1-.453.589H7.807A.441.441,0,0,1,7.4,13.9m12.587,8.467L13.376.317A.45.45,0,0,0,12.923,0H7.037a.492.492,0,0,0-.453.317L.02,22.366a.468.468,0,0,0,.453.589H4.321a.492.492,0,0,0,.453-.317L5.951,18.7a.45.45,0,0,1,.453-.317h7.289a.545.545,0,0,1,.453.317l1.177,3.939a.45.45,0,0,0,.453.317h3.848a.444.444,0,0,0,.362-.589"
                transform="translate(0 0)"
              />
            </g>
          </svg>
        </router-link>
      </div>

      <v-tabs
        fixed-tabs
        class="wk_main_nav"
        color="darken-1 "
        bg-color="transparent"
      >
        <v-tab v-if="loggedIn" to="/collections">Collections</v-tab>
        <!--        <v-tab v-if="loggedIn" to="/colors">Colors</v-tab>-->
        <!--        <v-tab v-if="loggedIn" to="/finishes">Finishes</v-tab>-->
        <v-tab v-if="loggedIn" to="/producibility">Producibility</v-tab>
        <v-tab v-if="loggedIn" to="/stocks">Stocks</v-tab>
        <v-tab @click="logout">
          <v-icon icon="mdi-logout"></v-icon>
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-main class="d-flex" style="min-height: 300px; margin-top: 64px">
      <router-view :key="this.$route.params"></router-view>
    </v-main>

    <!--v-main class="d-flex" style="min-height: 300px;" v-else>
      <Login v-on:login-success="isLoggedIn = $event"></Login>
    </v-main-->
  </v-app>
</template>

<style lang="scss">
@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("https://abetlaminati.com/wp-content/uploads/2022/05/NeueMontreal-Regular.woff")
    format("woff");
}

@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: url("https://abetlaminati.com/wp-content/uploads/2022/05/NeueMontreal-Bold.woff")
    format("woff");
}

@font-face {
  font-family: "Neue Montreal";
  font-style: italic;
  font-weight: normal;
  font-display: auto;
  src: url("https://abetlaminati.com/wp-content/uploads/2022/05/NeueMontreal-Italic.woff")
    format("woff");
}

@font-face {
  font-family: "Neue Montreal";
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  src: url("https://abetlaminati.com/wp-content/uploads/2022/05/NeueMontreal-BoldItalic.woff")
    format("woff");
}

html,
body,
body * {
  font: normal 100%/1.5 "Neue Montreal", sans-serif;
  line-height: 1.3em;
}

header.v-toolbar .v-toolbar__content {
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
}

.wk_logo_container > a {
  display: block;
}

#wk_main_navbar {
  position: fixed;
  z-index: 9999;
}

#abet_logo {
  display: block;

  * {
    fill: white;
  }
}

.wk_main_nav .v-btn__content {
  color: white;
  text-decoration: none;
  font-weight: 700;
  text-transform: none;
  font-size: 16px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ASAP",
  data: () => ({}),
  computed: {
    ...mapGetters("auth", ["loggedIn"]),
  },
  methods: {
    // userHasRole,
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
