//import '../public/themes/md-dark-indigo/theme.css'
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "../public/themes/wk-theme/theme.css";
import "../public/themes/wk-theme/wk-theme.scss";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// Vuetify
import { createVuetify } from "vuetify";
import "vuetify/styles";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { md1 } from "vuetify/blueprints";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = createVuetify({
  theme: {
    myCustomTheme: {
      dark: false,
      colors: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#000000",
        "primary-darken-1": "#000000",
        "primary-lighten-1": "#FFFFFF",
        secondary: "#FFFFFF",
        "secondary-darken-1": "#e9e9e9",
        "secondary-ligten-1": "#e9e9e9",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
  blueprint: md1,
});

// Primevue (for datatable)
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import Button from "primevue/button";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Paginator from "primevue/paginator";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Breadcrumb from "primevue/breadcrumb";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";
import Tooltip from "primevue/tooltip";

// Custom components
import StandardComponent from "@/components/StandardComponent.vue";
import DecorativoComponent from "@/components/DecorativoComponent.vue";
import NewStandardComponent from "@/components/NewStandardComponent.vue";
import DataComponent from "@/components/DataComponent.vue";
import ConsociateComponent from "@/components/ConsociateComponent.vue";
import DesignComponent from "@/components/DesignComponent.vue";
import { API_URL } from "@/utils/const";

const app = createApp(App);

app
  .use(store)
  .use(vuetify)
  .use(router)
  .use(PrimeVue, { unstyled: false })
  .mount("#app");

app
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("ColumnGroup", ColumnGroup)
  .component("DataView", DataView)
  .component("Row", Row)
  .component("Paginator", Paginator)
  .component("Button", Button)
  .component("InputText", InputText)
  .component("Checkbox", Checkbox)
  .component("MultiSelect", MultiSelect)
  .component("Breadcrumb", Breadcrumb)
  .component("Dropdown", Dropdown)
  .component("Autocomplete", AutoComplete);

app.directive("tooltip", Tooltip);

app.component("Standard", StandardComponent);
app.component("Decorativo", DecorativoComponent);
app.component("NewStandard", NewStandardComponent);
app.component("Data", DataComponent);
app.component("Consociate", ConsociateComponent);
app.component("Design", DesignComponent);

// axios global config
// axios.interceptors.request.use((config) => {
//   let data = localStorage.getItem("user");
//   const token = JSON.parse(data).accessToken;
//   if (token) {
//     // todo convert actual token in Bearer as this
//     config.headers.Authorization = `Bearer ${token}`;
//   }
// });
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 451) {
//       // todo replace with used error code
//       // redirect
//     } else {
//       // show generic error, take generic actions
//     }
//     return Promise.reject(error);
//   }
// );
axios.defaults.baseURL = API_URL;
app.config.globalProperties.$axios = axios;
